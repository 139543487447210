import { Margin, Rowing } from "@mui/icons-material";
import { Box, Button, FormControl, FormControlLabel, FormLabel, InputAdornment, Radio, RadioGroup, TextField } from "@mui/material";
import { Form, useNavigate } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ThemedTextField from "../../theme/ThemedTextField";
import ThemedButton from "../../theme/ThemedButton";
import { useState } from "react";

export interface GenericData {
    age: number
    height: number
    weight: number
    gender: string
}

interface GenericDataProps {
    setData: (data: {}) => void
}

const Step1 = (props: GenericDataProps) => {

    const [formData, setFormData] = useState<GenericData>({
        age: 0,
        height: 0,
        weight: 0,
        gender: '',
    });

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {

        const target = {
            name: e.target.id,
            value: e.target.value
        }

        const { name, value } = target;

        setFormData((prevData) => {
            // Converte i valori numerici solo per i campi age, height, weight
            const updatedValue = name === "age" || name === "height" || name === "weight"
              ? value === "" // gestisci il caso in cui il valore sia una stringa vuota
                ? 0 // o un valore predefinito
                : Number(value) // converte la stringa in un numero
              : value; // lascia invariato il valore per 'gender'
      
            return {
              ...prevData,
              [name]: updatedValue,
            };
          });

        console.log(`Invio i dati al database in step1 ${formData.age} - 
            ${formData.height} - ${formData.weight} - ${formData.gender}`);

        props.setData(formData)
    }

    return (<>
        <Box sx={{ p: 2, width: '100%', textAlign: 'center' }}>
            <h3>Dati Anagrafici</h3>
            <FormControl sx={{ m: 1, width: '50ch' }} variant="outlined">
                <ThemedTextField
                    label="Età"
                    id="age"
                    type="number"
                    placeholder="31"
                    onChange={handleChange}

                />
                <ThemedTextField
                    label="Altezza"
                    id="height"
                    type="number"
                    placeholder="170"
                    onChange={handleChange}
                    style={{ marginTop: '20px', marginBottom: '20px' }}
                />
                <ThemedTextField
                    label="Peso"
                    id="weight"
                    type="number"
                    onChange={handleChange}
                    placeholder="70"
                />
                <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label" sx={{ display: 'inline-block', marginTop: '20px' }}>Sesso</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        onChange={handleChange}
                        name="radio-buttons-group">
                        <FormControlLabel value="female" control={<Radio />} label="Femmina" />
                        <FormControlLabel value="male" control={<Radio />} label="Maschio" />
                        <FormControlLabel value="other" sx={{ whiteSpace: 'nowrap' }} control={<Radio />} label="Preferico non specificare" />
                    </RadioGroup>
                </FormControl>
            </FormControl>
        </Box>
    </>)
}
export default Step1;