import React from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TelegramIcon from '@mui/icons-material/Telegram';

import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    WhatsappShareButton
} from "react-share";


interface ShareTabProps {
    color?: 'negative' | 'color' | 'black' | 'white'
    url: string
    title?: string
    body?: string

}

const ShareTab: React.FC<ShareTabProps> = ({ url, title, body, color }) => {

    var style = styles.black

    if (color) {
        switch (color) {
            case 'negative': style = styles.negative; break
            case 'black': style = styles.black; break
            case 'white': style = styles.white; break
        }
    }

    return (<>
        <WhatsappShareButton
            url={url}
            title={title}
            separator={body}
            windowWidth={900}
            windowHeight={600}>
            <WhatsAppIcon style={{ ...style, padding: 7, borderRadius: 50 }}></WhatsAppIcon>
        </WhatsappShareButton>
        <FacebookShareButton
            url={url}
            title={title}
            windowWidth={900}
            windowHeight={600}>
            <FacebookIcon style={{ ...style, padding: 7, borderRadius: 50 }}></FacebookIcon>
        </FacebookShareButton>
        <EmailShareButton
            url={url}
            title={title}
            separator={body}
            windowWidth={900}
            windowHeight={600}>
            <EmailIcon style={{ ...style, padding: 7, borderRadius: 50 }} />
        </EmailShareButton>
        <LinkedinShareButton
            url={url}
            title={title}
            summary={title}
            windowWidth={900}
            windowHeight={600}>
            <LinkedInIcon style={{ ...style, padding: 7, borderRadius: 50 }} />
        </LinkedinShareButton>
        <TelegramShareButton
            url={url}
            title={title}
            windowWidth={900}
            windowHeight={600}>
            <TelegramIcon style={{ ...style, padding: 7, borderRadius: 50 }} />
        </TelegramShareButton>
    </>)
}

const iconColors = {
    backgroundColor: 'white',
    wa: {
        color: '#25d366'
    },
    fb: {
        color: '#3b5998'
    }
}

const styles = {
    negative: {
        backgroundColor: '#25d366',
        color: 'white'
    },
    black: {
        backgroundColor: 'black',
        color: 'white'
    },
    white: {
        backgroundColor: 'white',
        color: 'black'
    }
}
export default ShareTab