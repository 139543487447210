import React, { useEffect, useState } from 'react';
import { UserItem } from "./UserList"
import { Box, Typography } from '@mui/material';
import { capitalizeFirstLetter } from '../../utils/dataUtils';

interface UserContentProps {
    user: UserItem

}
const UserContent: React.FC<UserContentProps> = ({ user }) => {

    return (<><Box color={'#757575'}>
        <Typography fontSize={12} m={0}>Dashboard</Typography>
        <Typography variant='h5' m={0}>{capitalizeFirstLetter(user.firstName)} {capitalizeFirstLetter(user.lastName)}</Typography>

    </Box></>)
}

export default UserContent