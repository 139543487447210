import Step1 from "./Step1"
import Step2 from "./Step2"
import Step3 from "./Step3"

interface InitialStepperProps {

    activeStep?: number
    setData: (data: {}) => void

}

const InitialStepper = (props: InitialStepperProps) => {
    
    const step = props.activeStep

    if(step === 0){
        //get data of step 1
        return(<Step1
        setData={props.setData}
        />)
    } else if (step === 1){
        return(<Step2/>)
    }else {
        return(<Step3/>)
    }
    
}

export default InitialStepper