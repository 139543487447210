import React from "react"
import ThemedListItemDrawer from "../theme/ThemedListItemDrawer"
import { List } from "@mui/material"
import Divider from '@mui/material/Divider';

/*ICONS */
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';

/**PAGES */
import Dashboard from "../pages/Dashboard";
import Pyramid from "../pages/Pyramid";
import UserTab from "../pages/usersMenu/UserTab";


interface NavBarMenuProps {
    open: boolean
    setContent: (content: React.ReactNode) => void;
    onClick?: () => void
}

const NavBarMenu = (props: NavBarMenuProps) => {
    const minHeight = 48

    return (
        <List>
            <ThemedListItemDrawer
                key='Dashboard'
                text='Dashboard'
                minHeight={minHeight}
                icon={<DashboardIcon />}
                open={props.open}
                setContent={props.setContent}
                content={<Dashboard />}
            />
            <Divider />

            <ThemedListItemDrawer
                key='Pyramid'
                text='Pyramid'
                minHeight={minHeight}
                icon={<ChangeHistoryIcon />}
                open={props.open}
                setContent={props.setContent}
                content={<Pyramid />}
            />
            <ThemedListItemDrawer
                key='Users'
                text='Users'
                minHeight={minHeight}
                icon={<PeopleAltIcon />}
                open={props.open}
                setContent={props.setContent}
                content={<UserTab />}
            />
        </List>
    )

}

export default NavBarMenu