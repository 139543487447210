import { Box, Typography } from "@mui/material";
import { UserInfo } from "./login/login_service";
import { getSavedUserInfo } from "../utils/localStorageUtils";
import { useEffect, useState } from "react";
import { capitalizeFirstLetter } from "../utils/dataUtils";

const Dashboard: React.FC = () => {

    const [userInfo, setUserInfo] = useState<UserInfo>()

    const handleUserInfo = () => {
        try {
            const ui = getSavedUserInfo()
            setUserInfo(ui)
        } catch (error) {
            console.error('User info non presenti nel database - DESKTOP');
        }

    }

    useEffect(() => {
        handleUserInfo()
    }, [])

    return (<>
        <Typography fontSize={12} m={0}>Dashboard</Typography>
        <Typography variant='h5' m={0}>{capitalizeFirstLetter(userInfo?.given_name)} {capitalizeFirstLetter(userInfo?.family_name)}</Typography>
    </>)
}
export default Dashboard;