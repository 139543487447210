import { AccountCircle } from "@mui/icons-material";
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, FormControlLabel, FormGroup, IconButton, Menu, MenuItem, Switch, Toolbar, Typography } from "@mui/material";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { fetch_logout, ResponseCustom } from "../pages/login/login_service";
import MiniDrawer from "./MiniDrawer";

const Navbar: React.FC = () => {

    const [cookie, setCookie, removeCookie] = useCookies(['auth']);

    async function logout() {

        let access_token = localStorage.getItem("access_token")
        let refresh_token = localStorage.getItem("refresh_token")

        if (access_token && refresh_token) {
            const response: ResponseCustom = await fetch_logout(access_token, refresh_token)

            localStorage.removeItem("access_token")
            localStorage.removeItem("refresh_token")
            localStorage.removeItem("userInfo")
            setCookie("auth", false)
        }

    }

    return (<>
        <MiniDrawer
            logout={logout}
        ></MiniDrawer>
    </>)
}
export default Navbar;