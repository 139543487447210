import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { Box, IconButton, InputAdornment, InputBase, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import UserList, { UserItem } from './UserList';
import { fetch_users_list, UserInfo } from '../login/login_service';
import { getSavedAccessToken, getSavedUserInfo } from '../../utils/localStorageUtils';

interface UserTabProps {
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
        backgroundColor: '#1A2027',
    }),
}));

const UserTab: React.FC<UserTabProps> = (props) => {

    const [userInfo, setUserInfo] = useState<UserInfo>()
    const [users, setUsers] = useState<UserItem[] | undefined>()
    const [content, setContent] = React.useState<React.ReactNode>(undefined);
    const [query, setQuery] = useState<object | undefined>()
    const [messages, setMessages] = useState<string>()

    const handleUserInfo = () => {
        try {
            const ui = getSavedUserInfo();
            if (ui !== userInfo) { // Check if there's an actual change.
                setUserInfo(ui);
            }
        } catch (error) {
            console.error('Error retrieving user info', error);
        }
    };


    const handleSearch = (inputValue: string) => {
        setQuery({ ...query, firstName: inputValue })
    }

    const handleUserList = async (queryInpu: {}, message: string) => {
        if (queryInpu) {
            const response = await fetch_users_list(queryInpu, getSavedAccessToken());

            if (response.status == 200) {
                const usersArray = response.data as any[];

                const usersItems: UserItem[] = usersArray.map(user => ({
                    email: user["email"],
                    firstName: user["firstName"],
                    lastName: user["lastName"],
                    id: user["id"],
                    username: user["username"]
                }));

                setUsers(usersItems);

                if (usersArray.length == 0) {
                    setUsers(undefined);
                    setMessages(!message ? "Non hai ancora nessun utente associato. Condividi il tuo link per iniziare!" : message)
                }
            } else {
                console.error("Errore lista utenti", response.status, response.data);
                
                setUsers(undefined);
                setMessages("Caricamento in corso...")
            }
        } else {
            setUsers(undefined);
            setMessages("Caricamento in corso...")
        }
    };

    useEffect(() => {
        handleUserInfo();
    }, []);

    useEffect(() => {
        if (userInfo) {
            handleUserList({ prof_id: userInfo.sub }, "Non hai ancora nessun utente associato. Condividi il tuo link per iniziare!");
        }
    }, [userInfo])

    useEffect(() => {
        if (query) {
            handleUserList(query, "Nessuno degli utenti corrisponde ai criteri di ricerca");
        }
    }, [query])

    return (<>
        <Grid container spacing={1}>
            <Grid size={3}>
                <Box sx={{ '& > :not(style)': { m: 1 } }}>
                    <TextField
                        id="search user"
                        placeholder='Cerca utente ...'
                        slotProps={{
                            input: {
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            },
                        }}
                        variant="outlined"
                        helperText='es. mario'
                        onChange={(e) => handleSearch(e.target.value)}
                    />
                </Box>
                <UserList
                    users={users}
                    setContent={setContent}
                    messages={messages}
                />
            </Grid>
            <Grid size={9} pl={8}>
                {content}
            </Grid>
        </Grid>

    </>)
}

export default UserTab