import { Box, Chip, Divider, FormControl, IconButton, Stack, Switch, Typography } from "@mui/material"
import Grid from '@mui/material/Grid2';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import React, { useEffect, useState } from "react";
import { fetch_new_attribute } from "../login/login_service";
import { getSavedAccessToken, getSavedRefreshToken } from "../../utils/localStorageUtils";
import ThemedAutocomplete from "../../theme/ThemedAutocomplete";
import ThemedTextField from "../../theme/ThemedTextField";

interface ServicesProps {
    editing: boolean
    needs_treated?: string[]
    phone?: string
    office_address?: string
    remote_visit?: boolean
    first_visit_cost?: number
    handleData: (data: any) => void

}

const Services = (props: ServicesProps) => {

    const { editing, needs_treated, phone, office_address, remote_visit, first_visit_cost, handleData } = props

    const [remote, setRemote] = useState<boolean>(remote_visit ?? false)
    const [accessToken, setAccessToken] = useState<string>(getSavedAccessToken())
    const [refreshToken, setRefreshToken] = useState<string>(getSavedRefreshToken())
    const [needs, setNeeds] = useState<any[]>();
    const [cost, setCost] = useState<number>()
    const [phoneData, setPhoneData] = useState<string | undefined>(phone)
    const [addressData, setAddressData] = useState<string | undefined>(office_address)
    const [data, setData] = useState<Record<string, any | undefined>>()


    const handleRemoteVisitChange = async () => {
        await fetch_new_attribute({ "remote_visit": String(!remote) }, accessToken, refreshToken, "desktop")
    }


    const handleAddNeeds = (elements: string[]) => {
        setNeeds(elements)
        console.log(elements);


        setData((prevData) => ({
            ...prevData,
            needs_treated: elements,
        }));
    }

    const handleCost = (elements: string[]) => {
        setCost(Number(elements[0]))
        console.log(elements[0]);


        setData((prevData) => ({
            ...prevData,
            first_visit_cost: elements[0],
        }));

    }

    const handlePhone = (element: string) => {
        setPhoneData(element)
        console.log(element);


        setData((prevData) => ({
            ...prevData,
            phone: element,
        }));

    }

    const handleAddress = (element: string) => {
        setAddressData(element)
        console.log(element);


        setData((prevData) => ({
            ...prevData,
            office_address: element,
        }));

    }

    useEffect(() => {
        const initData = { ...data }
        initData['phone'] = phone ?? ''
        setData(initData)
    }, [])

    useEffect(() => {
        handleData(data)
    }, [data])


    return (<>
        <Box>
            <ThemedAutocomplete
                options={[]}
                disabled={editing}
                values={needs_treated ?? []}
                alternativeColors={true}
                label="Bisogni nutrizionali trattati"
                setElemets={handleAddNeeds}
            />
        </Box>

        <Box sx={{ marginTop: 3, marginBottom: 3 }} >

            <ThemedAutocomplete
                options={[]}
                disabled={editing}
                values={!first_visit_cost ? [] : [`${first_visit_cost}  €`]}
                color="success"
                alternativeColors={false}
                label="Costo prima visita"
                setElemets={handleCost}
            />
        </Box>

        <Box sx={{ marginTop: 3, marginBottom: 3 }} >
            <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid size={{ xs: 4, sm: 2, md: 2 }} spacing={1}>
                    <Typography mb={2} width={200} color="rgba(0, 0, 0, 0.6)">Disponibile per visite a distanza</Typography>

                </Grid>
                <Grid size={{ xs: 4, sm: 6, md: 10 }}>
                    <Switch
                        disabled={editing}
                        defaultChecked={remote}
                        onChange={handleRemoteVisitChange}
                        color="secondary" />
                </Grid>
            </Grid>
        </Box>
        <Box>
            {editing ?
                <Stack direction="row" spacing={1}>
                    <Chip icon={<LocalPhoneIcon />} label={phoneData} style={
                        { backgroundColor: "white" }
                    } />

                    <Chip icon={<LocationOnIcon />} label={addressData} style={
                        { backgroundColor: "white" }
                    } />
                </Stack>
                :
                <>
                    <Box>
                        <ThemedTextField
                            label="Numero di Telefono"
                            multiline
                            disabled={editing}
                            defaultValue={phoneData}
                            type="text"
                            focused={true}
                            value={phoneData}
                            style={{mb: 3}}
                            onChange={(e) => handlePhone(e.target.value)}
                        />

                        <ThemedTextField
                            label="Indirizzo studio medico"
                            multiline
                            disabled={editing}
                            defaultValue={addressData}
                            type="text"
                            focused={true}
                            helperText="es: via roma 1, Milano 12345 (MI)"
                            value={addressData}
                            onChange={(e) => handleAddress(e.target.value)}
                        />
                    </Box>

                </>
            }
        </Box>
    </>)

}

export default Services;