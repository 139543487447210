import { Box, Chip, Divider, IconButton, Input, Stack, TextField, Typography, FormLabel, Autocomplete } from "@mui/material"
import Grid from '@mui/material/Grid2';
import ThemedButton from "../../theme/ThemedButton";
import { Link, useNavigate } from "react-router-dom";
/*Social icons */
import LinkIcon from '@mui/icons-material/Link';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

import ThemedTextField from "../../theme/ThemedTextField";
import ThemedAutocomplete from "../../theme/ThemedAutocomplete";
import { useEffect, useState } from "react";
import { fetch_new_attribute, fetch_refresh_token } from "../login/login_service";
import { getSavedAccessToken, getSavedRefreshToken, saveInLocalStorage } from "../../utils/localStorageUtils";

interface AboutProps {
    editing: boolean
    summary?: string
    specializations?: string[]
    languages?: string[]
    links?: string[]
    handleData: (data: any) => void
}

const About = (props: AboutProps) => {
    const { editing, summary, links, specializations, languages, handleData } = props
    const [data, setData] = useState<Record<string, any | undefined>>()
    const [specs, setSpecs] = useState<any[]>();
    const [langs, setLangs] = useState<any[]>();
    const [summaryData, setSummaryData] = useState<string>()


    function rilevaIcona(link: string) {

        if (link.includes('facebook')) {
            return <FacebookIcon />
        } else if (link.includes('instagram')) {
            return <InstagramIcon />
        } else if (link.includes('linkedin')) {
            return <LinkedInIcon />
        } else {
            return <LinkIcon />
        }
    }

    const handleSummary = (element : string) => {
        setSummaryData(element)
        console.log(element);
        

        setData((prevData) => ({
            ...prevData,
            about_me: element,
        }));
    }

    const handleAddSpecs = (elements: string[]) => {
        setSpecs(elements)
        console.log(elements);
        

        setData((prevData) => ({
            ...prevData,
            specializations: elements,
        }));
    }

    const handleAddLangs = (elements: string[]) => {
        setLangs(elements)

        setData((prevData) => ({
            ...prevData,
            languages: elements,
        }));
    }

    useEffect(() => {
        const initData = { ...data }
        initData['about_me'] = summary ?? ''
        setData(initData)
    }, [])

    useEffect(() => {
        handleData(data)
    }, [data])

    return (<>

        <Box>
            <ThemedTextField
                label="Summary"
                multiline
                disabled={editing}
                defaultValue={summary}
                type="textarea"
                fullWidth={true}
                minRows={3}
                maxRows={10}
                focused={true}
                value={data?.about_me}
                onChange={(e) => handleSummary(e.target.value)}
            />
        </Box>

        <Box sx={{ marginTop: 3, marginBottom: 3 }} >
            <ThemedAutocomplete
                options={[]}
                disabled={editing}
                values={specializations ?? []}
                alternativeColors={true}
                label="Specializzazioni"
                setElemets={handleAddSpecs}
            />
        </Box>

        <Box sx={{ marginTop: 3, marginBottom: 3 }} >
            <ThemedAutocomplete
                options={[]}
                disabled={editing}
                values={languages ?? []}
                alternativeColors={false}
                label="Lingue"
                setElemets={handleAddLangs}
            />
        </Box>

        <Box sx={{ marginTop: 3, marginBottom: 3 }} >
            <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid size={{ xs: 4, sm: 1.5, md: 1.5 }}>
                    <Typography mb={2} width={70} color="rgba(0, 0, 0, 0.6)">Links</Typography>
                </Grid>
                <Grid size={{ xs: 4, sm: 6, md: 10 }}>
                    {
                        links?.map(link => {
                            return (
                                <>
                                    <IconButton>
                                        <Link to={link}>
                                            {rilevaIcona(link)}
                                        </Link>
                                    </IconButton>
                                </>
                            )
                        })
                    }
                </Grid>
            </Grid>
        </Box>

    </>)
}

export default About
