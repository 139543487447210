import React, { useEffect, useState } from 'react';
import { FormControl, Box, Alert, Typography } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { fetch_new_attribute, fetch_token, ResponseCustom } from './login_service';
import ThemedTextField from '../../theme/ThemedTextField';
import ThemedPassword from '../../theme/ThemedPassword';
import ThemedButton from '../../theme/ThemedButton';
import { getSavedRefreshToken } from '../../utils/localStorageUtils';

interface LoginProps {
    isAuth: (auth: boolean) => void
}

const Login: React.FC<LoginProps> = (props) => {

    const navigate = useNavigate();
    const { invite, env } = useParams()

    const [cookie, setCookie] = useCookies(['auth']);

    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [valError, setValError] = useState<boolean>(false)
    const [typeUser, setTypeUser] = useState(!env ? 'desktop' : env);


    useEffect(() => {
        if (cookie.auth) {
            navigate('/');
        }
    })

    /**
     * Richiama il metodo del service e gestisce lo stato
     * per passare le informazioni di username e password
     * Gestisce il cookie di sessione
    */
    async function login() {

        if (!username || username === "" || username === " "
            || !password || password === "" || password === " ") {
            setValError(true)
        } else {
            console.log(typeUser);
            
            const response: ResponseCustom = await fetch_token(username, password, typeUser)

            if (response.status == 200) {
                localStorage.setItem("access_token", response.data.access_token)
                localStorage.setItem("refresh_token", response.data.refresh_token)
                localStorage.setItem("userInfo", JSON.stringify(response.data.user_info))

                props.isAuth(true)

                if(typeUser == 'mobile' && invite){
                    try {
                        console.log("associo l'utente");
                        await fetch_new_attribute({'prof_id': invite}, response.data.access_token, response.data.refresh_token ,typeUser);
                    } catch (error) {
                        console.error("Errore durante l'associazione utente - professionista:", error);
                        alert("Ops! C'è stato un errore durante l'associazione. Riprova.");
                        return; 
                    }
                }

                /*if (!response.data.user_info.config_id
                    // todo quando si normalizzeranno i dati eliminare questo controllo!!
                    || response.data.user_info.config_id == 'pippo') {
                    console.log("vado allo step 1");
    
                    navigate("/config-stepper-handler")
                } else {
                }*/

                if(typeUser == 'desktop'){
                    navigate('/')
                }else {
                    // todo quando sarà deployata inviare l'utente direttamente all'app
                    console.log("vado in download");
                    
                    navigate('/download-app')
                }
            } else {
                setErrorMessage(response.data)
                props.isAuth(false)
            }
        }
    }

    return (<>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
            <Box sx={{ p: 2, width: '100%', textAlign: 'center' }}>
                <h2>Accedi</h2>
                <FormControl sx={{ m: 1, width: '50ch' }} variant="outlined">
                    <FormControl>
                        <ThemedTextField
                            required={true}
                            id="outlined-required"
                            style={{ mb: 2 }}
                            label="Username"
                            placeholder="Username"
                            error={valError}
                            value={username}
                            onChange={e => { setUsername(e.target.value); setValError(!e.target.value || e.target.value === "" || e.target.value === " ") }}
                            helperErrorText='Il valore di username è obbligatorio'
                        />
                    </FormControl>
                    <ThemedPassword
                        id='outlined-password-input'
                        label='Password'
                        value={password}
                        error={valError}
                        onChange={e => {setPassword(e.target.value); setValError(!e.target.value || e.target.value === "" || e.target.value === " ")}}
                        onKeyUp={login}
                    />
                    <ThemedButton
                        value='Accedi'
                        onClick={login}
                        style={{ marginTop: '20px', marginBottom: '20px' }}
                    />
                    {errorMessage ? (
                        <Alert severity="error">{errorMessage}</Alert>
                    ) : <></>}
                </FormControl>
                <Typography>Non sei ancora registrato? <Link to={!invite? "/sign-up": `/sign-up/${env}/invite/${invite}`} style={{color: 'blue'}}>Crea un account</Link></Typography>
            </Box>
        </div>
    </>
    );
};

export default Login;