import axios from 'axios';

export interface ResponseCustom {
    status: number | undefined;
    data: any
}

export interface UserInfo {
    sub: string
    preferred_username: string
    given_name: string
    locale: string | undefined
    family_name: string
    email: string
    email_verified: boolean
    roles: [string]
    diet_code: string | undefined
    config_id: string | undefined
    // account
    piva: string | undefined
    vat_address: string | undefined
    // about
    about_me: string | undefined
    title: string | undefined
    social_link: string[] | undefined
    languages: string[] | undefined
    specializations: string[] | undefined
    // profilo professionista
    needs_treated: string[] | undefined
    phone: string | undefined
    office_address: string | undefined
    remote_visit: boolean | undefined
    first_visit_cost: number | undefined
}

const host = process.env.REACT_APP_LOGIN_URL

async function fetch_token(username: string, password: string, environment?: string) {

    return await axios.post(`${host}login`, {
        username: username,
        password: password
    }, {
        headers: {
            'XX-environment': !environment ? 'desktop' : environment
        }
    })
        .then(response => {
            console.log("Login successfully", response);

            const userInfo: UserInfo = response.data.user_info

            const data = {
                "access_token": response.data.access_token,
                "refresh_token": response.data.refresh_token,
                "user_info": userInfo
            }
            const res: ResponseCustom = {
                status: response.status,
                data: response.data
            };

            return res
        })
        .catch(error => {
            let data: any;

            if (axios.isAxiosError(error) && error.response) {
                data = error.response.data.message

                if (!data) {
                    data = error.response.data.detail
                }
            } else {
                data = `Unexpected error: ${error.message}`
            }

            const res: ResponseCustom = {
                status: error.status,
                data: data
            };

            return res
        });
}

async function fetch_user_info(token: string | null, environment?: string) {

    return await axios.get(`${host}user-info`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                'XX-environment': !environment ? 'desktop' : environment
            }
        })
        .then(response => {

            const user_info: UserInfo = response.data

            const res: ResponseCustom = {
                status: response.status,
                data: user_info
            };

            return res
        })
        .catch(error => {
            let data: any;

            if (axios.isAxiosError(error) && error.response) {
                data = error.response.data.message
            } else {
                data = `Unexpected error: ${error.message}`
            }

            const res: ResponseCustom = {
                status: error.status,
                data: data
            };

            return res
        })
}

async function fetch_refresh_token(refresh_token: string | null) {

    return axios.get(`${host}refresh`,
        //todo capire come se lo aspetta negli header il be
        {
            headers: {
                "XX-refresh-token": `${refresh_token}`,
                'XX-environment': 'desktop'
            }
        }
    )
        .then(response => {

            console.log("Login successfully", response);

            const res: ResponseCustom = {
                status: response.status,
                data: response.data
            };

            return res
        })
        .catch(error => {
            let data: any;

            if (axios.isAxiosError(error) && error.response) {
                data = error.response.data.message
            } else {
                data = `Unexpected error: ${error.message}`
            }

            const res: ResponseCustom = {
                status: error.status,
                data: data
            };

            return res
        })

}

async function fetch_logout(access_token: string | null, refresh_token: string | null) {

    return await axios.post(`${host}logout`, {}, {
        headers: {
            Authorization: `Bearer ${access_token}`,
            "XX-refresh-token": `${refresh_token}`,
        }
    }).then(response => {
        console.log("Logout successfully", response);

        const res: ResponseCustom = {
            status: response.status,
            data: response.data
        };

        return res
    }).catch(error => {
        let data: any;

        if (axios.isAxiosError(error) && error.response) {
            data = error.response.data.message
        } else {
            data = `Unexpected error: ${error.message}`
        }

        const res: ResponseCustom = {
            status: error.status,
            data: data
        };

        return res
    })

}

async function fetch_new_user(newUser: {}, typeEnv: string) {
    return await axios.post(`${host}new-user`, newUser, {
        headers: {
            'XX-environment': typeEnv,
            'Content-Type': 'application/json'
        }
    }).then(response => {
        console.log("Logout successfully", response);

        const res: ResponseCustom = {
            status: response.status,
            data: response.data
        };

        return res
    }).catch(error => {
        let data: any;

        if (axios.isAxiosError(error) && error.response) {
            data = error.response.data.message
        } else {
            data = `Unexpected error: ${error.message}`
        }

        const res: ResponseCustom = {
            status: error.status,
            data: data
        };

        return res
    })
}

async function fetch_new_attribute(attributes: {}, access_token: string | null, refresh_token: string, typeEnv: string) {
    //  'https://auth.dev.official.ovh/add-attribute' \
    return await axios.put(`${host}add-attribute`, attributes, {
        headers: {
            Authorization: `Bearer ${access_token}`,
            'XX-environment': typeEnv,
            "XX-refresh-token": `${refresh_token}`,
            'Content-Type': 'application/json'
        }
    }).then(response => {
        console.log("Logout successfully", response);

        const res: ResponseCustom = {
            status: response.status,
            data: response.data
        };

        return res
    }).catch(error => {
        let data: any;

        if (axios.isAxiosError(error) && error.response) {
            data = error.response.data.message
        } else {
            data = `Unexpected error: ${error.message}`
        }

        const res: ResponseCustom = {
            status: error.status,
            data: data
        };

        return res
    })
}

async function fetch_users_list(query: {}, access_token: string | null) {
    return await axios.post(`${host}users`, query, {
        headers: {
            Authorization: `Bearer ${access_token}`,
            'Content-Type': 'application/json'
        }
    }).then(response => {
        console.log("User list is returned successfully");

        const res: ResponseCustom = {
            status: response.status,
            data: response.data
        };

        return res
    }).catch(error => {
        let data: any;

        if (axios.isAxiosError(error) && error.response) {
            data = error.response.data.message
        } else {
            data = `Unexpected error: ${error.message}`
        }

        const res: ResponseCustom = {
            status: error.status,
            data: data
        };

        return res
    })
}

export { fetch_token, fetch_user_info, fetch_refresh_token, fetch_logout, fetch_new_user, fetch_new_attribute, fetch_users_list }