import { Visibility, VisibilityOff } from '@mui/icons-material';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import React, { KeyboardEvent, useState } from 'react';


interface ThemedPasswordProps {
    id?: string
    required?: boolean | false
    style?: {}
    label?: string
    value?: string
    error?: boolean
    helperErrorText?: string
    helperText?: string
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyUp?: (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void

}


const ThemedPassword = (props: ThemedPasswordProps) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <FormControl sx={{ width: '100%' }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">{props.label}</InputLabel>
            <OutlinedInput
                required={props.required}
                error={props.error}
                id={props.id}
                sx={props.style}
                label={props.label}
                type={showPassword ? 'text' : 'password'}
                value={props.value}
                onChange={props.onChange}
                onKeyUp={event => {
                    if (event.key == "Enter") {
                        props.onKeyUp?.(event)
                    }
                }}
                autoComplete="current-password"
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword((show) => !show)}
                            onMouseDown={event => event.preventDefault()}
                            onMouseUp={event => event.preventDefault()}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                } />
        </FormControl>
    )
}

export default ThemedPassword;