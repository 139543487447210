const capitalizeFirstLetter = (str?: string): string => {
    if (!str) return ''; // Gestisce stringhe vuote o undefined
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const getShareMessage = (firstName: string, lastName: string, url : string) => {
    const message = 
    `Ciao 👋 ,
    
    Sono felice di annunciare una grande novità che renderà ancora più semplice e intuitivo il nostro percorso insieme! 
    Ho introdotto un nuovo gestionale online che ti permetterà di accedere al tuo piano alimentare, 
    monitorare i progressi e restare sempre aggiornato con i miei consigli.
    
    Cosa fare?
    1️⃣ Registrati subito cliccando su questo link: ${url}
    2️⃣ Scarica l’app dedicata per avere tutto a portata di mano, ovunque tu sia!
    
    👉 DietMatc è disponibile su:
    
    Google Play Store
    Apple App Store
    
    Con questa piattaforma sarà più semplice seguirti nel tuo percorso, con funzionalità come:
    ✅ Accesso al tuo piano alimentare personalizzato
    ✅ Promemoria automatici
    ✅ Possibilità di inviare domande o aggiornamenti
    
    Ti aspetto nel gestionale per continuare insieme il tuo cammino verso il benessere! 💚
    
    Un caro saluto! ${firstName} ${lastName}`
          return message
  }

  export {capitalizeFirstLetter, getShareMessage}