import { TextField } from '@mui/material';
import React from 'react';


interface ThemedTextFieldProps {
    required?: boolean | false
    id?: string
    style?: {}
    label?: string
    type?: string
    placeholder?: string
    value?: string
    error?: boolean
    helperErrorText?: string
    helperText?: string
    multiline?: boolean | false
    disabled?: boolean | false
    defaultValue?: any
    fullWidth?: boolean | false
    rows?: number
    minRows?: number
    maxRows?: number
    focused?: boolean | false
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}


const ThemedTextField = (props: ThemedTextFieldProps) => {

    return (<TextField
        error={props.error}
        required={props.required}
        id={props.id}
        sx={props.style}
        label={props.label}
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        multiline={props.multiline}
        disabled={props.disabled}
        defaultValue={props.defaultValue}
        fullWidth={props.fullWidth}
        rows={props.rows}
        minRows={props.minRows}
        maxRows={props.maxRows}
        focused={props.focused}
        helperText={props.error ? props.helperErrorText : props.helperText}
    />)

}


export default ThemedTextField;