import * as React from 'react';
import QRcode from 'react-qr-code'

interface QrCodeGeneratorProps {
    data: string
}

const QrCodeGenerator: React.FC<QrCodeGeneratorProps> = ({ data }) => {
    return (<>
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 20
        }}>
            <QRcode value={data} size={300} />
        </div>
    </>)
}

export default QrCodeGenerator