import { UserInfo } from "../pages/login/login_service"

const getSavedUserInfo = () => {
    const userInfoJson = localStorage.getItem("userInfo")

    if (!userInfoJson) {
        return undefined
    }

    const userInfo: UserInfo = JSON.parse(userInfoJson)
    return userInfo
}

const getSavedAccessToken = () => {
    return localStorage.getItem("access_token") ?? ""
}

const getSavedRefreshToken = () => {
    return localStorage.getItem("refresh_token") ?? ""
}

const saveInLocalStorage = (access_token: string, refresh_token: string, user_info : any) => {
    localStorage.setItem("access_token", access_token)
    localStorage.setItem("refresh_token", refresh_token)
    localStorage.setItem("userInfo", JSON.stringify(user_info))
}


export { getSavedUserInfo, getSavedAccessToken, getSavedRefreshToken, saveInLocalStorage }