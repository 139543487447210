import React from "react"
import ThemedListItemDrawer from "../theme/ThemedListItemDrawer"
import { List } from "@mui/material"

/*ICONS */
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

/**PAGES */
import Profile from "../pages/profile/Profile";

interface FooterMenuProps {
  open: boolean
  setContent?: (content: React.ReactNode) => void;
  onClick?: () => void
}

const FooterMenu = (props: FooterMenuProps) => {
  const minHeight = 12

  return (
    <List>
      <ThemedListItemDrawer
        key='Profile'
        minHeight={minHeight}
        secondaryText='Profile'
        open={props.open}
        setContent={props.setContent}
        content={<Profile />}
      />

      <ThemedListItemDrawer
        key='Logout'
        minHeight={minHeight}
        secondaryText='Logout'
        open={props.open}
        onClick={props.onClick}
      />
    </List>
  )

}

export default FooterMenu