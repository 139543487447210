import React, { useEffect, useState } from 'react';
import { Alert, Box, FormControl, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material"
import ThemedTextField from "../../theme/ThemedTextField";
import ThemedPassword from "../../theme/ThemedPassword";
import ThemedButton from "../../theme/ThemedButton";
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { fetch_new_attribute, fetch_new_user, fetch_token, ResponseCustom } from '../login/login_service';
import { useNavigate, useParams } from 'react-router-dom';

interface SignUpProps {
    data?: any
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
        backgroundColor: '#1A2027',
    }),
}));

const SignUp: React.FC<SignUpProps> = (props) => {

    const navigate = useNavigate()
    const { invite, env } = useParams()

    const [username, setUsername] = useState<string>('')
    const [firstname, setFirstname] = useState<string>('')
    const [lastname, setLastname] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [confirmPassword, setConfirmPassword] = useState<string>('')
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [valError, setValError] = useState<boolean>(false)
    const [typeUser, setTypeUser] = useState(!env ? 'desktop' : env);


    const handleSignUp = async () => {
        if (!username || !email || !password || !confirmPassword || confirmPassword != password) {
            setErrorMessage("valori mancati o errati")
        }
        else {
            setErrorMessage('')

            const newUser = {
                email: email,
                username: username,
                firstname: firstname,
                lastname: lastname,
                password: password
            }
            console.log(newUser);
            
            const response: ResponseCustom = await fetch_new_user(newUser, typeUser)

            if (response.status == 200) {
                if(typeUser == 'mobile' && invite){
                    try {
                        console.log("associo l'utente");
                        const responseToken: ResponseCustom = await fetch_token(username, password, 'mobile');
                        await fetch_new_attribute({'prof_id': invite}, responseToken.data.access_token, response.data.refresh_token, typeUser);
                    } catch (error) {
                        console.error("Errore durante l'associazione utente - professionista:", error);
                        alert("Ops! C'è stato un errore durante l'associazione. Riprova.");
                        return; // Termina il flusso in caso di errore
                    }
                }
                alert("Grazie per esserti unito a noi!")

                console.log(typeUser);
                
                if(typeUser == 'desktop'){
                    navigate('/login')
                }else {
                    navigate('/download-app')
                }
            } else {
                alert(`Ops! pare che ci sia stato un errore ${response.data}`)
            }
        }
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
            <Box sx={{ p: 2, width: '100%', textAlign: 'center' }}>
                <h2>Registrati</h2>
                    <FormControl>
                        <ThemedTextField
                            required={true}
                            id="outlined-required"
                            style={{ mb: 2 }}
                            label="Username"
                            placeholder="Username"
                            error={valError}
                            value={username}
                            onChange={e => { setUsername(e.target.value); setValError(!e.target.value || e.target.value === "" || e.target.value === " ") }}
                            helperErrorText='Il valore di username è obbligatorio'
                        />
                        <ThemedTextField
                            required={true}
                            id="outlined-required"
                            style={{ mb: 2 }}
                            label="Nome"
                            placeholder="Nome"
                            error={valError}
                            value={firstname}
                            onChange={e => { setFirstname(e.target.value); setValError(!e.target.value || e.target.value === "" || e.target.value === " ") }}
                            helperErrorText='Il valore di firstname è obbligatorio'
                        />
                        <ThemedTextField
                            required={true}
                            id="outlined-required"
                            style={{ mb: 2 }}
                            label="Cognome"
                            placeholder="Cognome"
                            error={valError}
                            value={lastname}
                            onChange={e => { setLastname(e.target.value); setValError(!e.target.value || e.target.value === "" || e.target.value === " ") }}
                            helperErrorText='Il valore di username è obbligatorio'
                        />
                        <ThemedTextField
                            required={true}
                            id="outlined-required"
                            style={{ mb: 2 }}
                            label="E-mail"
                            placeholder="mail@addres.net"
                            error={valError}
                            type='email'
                            value={email}
                            onChange={e => { setEmail(e.target.value); setValError(!e.target.value || e.target.value === "" || e.target.value === " ") }}
                            helperErrorText='Il valore per email è obbligatorio'
                        />
                    </FormControl>
                    <ThemedPassword
                        id='outlined-password-input'
                        label='Password'
                        required={true}
                        style={{ mb: 2 }}
                        value={password}
                        error={valError}
                        onChange={e => { setPassword(e.target.value); setValError(!e.target.value || e.target.value === "" || e.target.value === " ") }}
                        onKeyUp={handleSignUp}
                    />
                    <ThemedPassword
                        id='outlined-password-input'
                        label='Confirm Password'
                        value={confirmPassword}
                        style={{ mb: 2 }}
                        required={true}
                        error={valError}
                        onChange={e => {
                            setConfirmPassword(e.target.value); setValError(confirmPassword != password
                                && !e.target.value || e.target.value === "" || e.target.value === " ")
                        }}
                        onKeyUp={handleSignUp}
                    />
                    <ThemedButton
                        value='Registrati'
                        onClick={handleSignUp}
                        style={{ marginTop: '20px', marginBottom: '20px' }}
                    />
                    {errorMessage ? (
                        <Alert severity="error">{errorMessage}</Alert>
                    ) : <></>}
                <Typography>Privacy Policy</Typography>
            </Box>
        </div>
    )
}

export default SignUp