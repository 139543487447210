import { Autocomplete, Chip, TextField } from "@mui/material"
import { useEffect, useState } from "react"

interface ThemedAutocompleteProps {
    options: any[]
    values: any[]
    alternativeColors?: boolean
    disabled?: boolean
    size?: "small" | "medium"
    label: string
    placeholder?: string
    chipVariant?: "outlined" | "filled"
    color?: "primary" | "secondary" | "success" | "default" | "info" | "warning"
    setElemets: (elements: any[]) => void

}

const ThemedAutocomplete = (props: ThemedAutocompleteProps) => {

    const { options, disabled, values, alternativeColors, size, label, placeholder, chipVariant, color, setElemets } = props

    const [defaultValues, setDefaultValues] = useState<any[]>([])

    useEffect(() => {
        setDefaultValues(values ?? []);
        setElemets(defaultValues)
    }, [])

    return (<>
        <Autocomplete
            multiple
            disabled={disabled ?? false}
            options={options}
            value={defaultValues}
            onChange={(event, newValue) => {
                setDefaultValues(newValue)
                setElemets(newValue)
                
            }}
            freeSolo
            renderTags={(value: readonly string[], getTagProps) =>
                value.map((option: string, index: number) => {
                    const { key, ...tagProps } = getTagProps({ index });

                    tagProps.onDelete = () => {
                        const updatedValues = [...defaultValues];
                        console.log("update values", updatedValues);
                        
                        updatedValues.splice(index, 1); 
                        setDefaultValues(updatedValues); 
                        setElemets(updatedValues); 
                    };
                    let colorInput = color ?? "default";

                    if (alternativeColors ?? false) {
                        const colors: Array<"primary" | "secondary" | "success" | "default" | "info" | "warning"> =
                            ['primary', 'secondary', 'success', 'default', 'info', 'warning'];
                        colorInput = colors[index % colors.length];
                    }

                    return (
                        <Chip variant={chipVariant ?? "outlined"} color={colorInput} label={option} key={key} {...tagProps} size={size ?? "medium"} />
                    );
                })
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    placeholder={placeholder}
                />
            )}
        />
    </>)

}

export default ThemedAutocomplete