import React, { useEffect, useState } from 'react';

import { Navigate, Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import Login from './pages/login/Login';
import Navbar from './components/Navbar';
import Home from './pages/Dashboard';
import { useCookies } from 'react-cookie';
import { fetch_user_info, fetch_refresh_token, ResponseCustom, UserInfo } from './pages/login/login_service';
import ConfigStepperHandler from './pages/initialStepper/ConfigStepperHandler';
import SignUp from './pages/signUp/SignUp';
import { getSavedUserInfo } from './utils/localStorageUtils';
import DownloadApp from './pages/DownloadApp';

function App() {

  const [cookie, setCookie] = useCookies(['auth']);
  const [userInfoData, setUserInfoData] = useState<UserInfo>()

  async function userInfo() {
    let token = localStorage.getItem("access_token")

    if (token) {
      const response: ResponseCustom = await fetch_user_info(token)

      if (response.status == 200) {
        setUserInfoData(response.data)
        setCookie("auth", true);
      } else {
        setCookie("auth", false);
      }

    }
  }

  async function refreshToken() {
    let refresh_token = localStorage.getItem("refresh_token")

    if (refresh_token) {
      const response: ResponseCustom = await fetch_refresh_token(refresh_token)

      if (response.status == 200) {
        localStorage.setItem("access_token", response.data.access_token)
        localStorage.setItem("refresh_token", response.data.refresh_token)
        setCookie("auth", true);
      }
    }
    else {
      setCookie("auth", false);
    }
  }
  useEffect(() => {
    const loadUserData = async () => {

      await userInfo();
      if (cookie.auth == false) {
        await refreshToken();
      } 

      if(!localStorage.getItem("userInfo")){
        console.log('non trovo le user info');
        isAuth(false)
      }

    };

    loadUserData();
  }, [cookie]);

  const isAuth = (authInput: boolean) => {
    setCookie('auth', authInput)
  }

  const routes = [
    {
      path: "/",
      element: <Outlet />,
      children: [
        {
          path: "login/:env/invite/:invite",
          element: <Login isAuth={isAuth} />,
        },
        {
          path: "login",
          element: <Login isAuth={isAuth} />,
        },
        {
          path: "sign-up/:env/invite/:invite",
          element: <SignUp/>,
        },
        {
          path: "sign-up",
          element: <SignUp/>,
        },
        {
          path: "download-app",
          element: <DownloadApp/>,
        },
        {
          path: "config-stepper-handler",
          element: <ConfigStepperHandler />
        },
        {
          path: "/",
          element: <> {cookie.auth ? (<><Navbar /> <Outlet /></>) : (<Navigate to="/login" replace />)}</>,
          children: [
            {
              path: "/"
            }
          ]
        },
        /*{
          path: "*",
          element:<Navigate to="/login" replace />,
        },*/
      ],
    },
  ];



  const router = createBrowserRouter(routes);
  return (<>
    <RouterProvider router={router} />
  </>
  );
}

export default App;
