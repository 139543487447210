import Grid from '@mui/material/Grid2';
import { UserInfo } from "./login/login_service";
import { getSavedUserInfo } from "../utils/localStorageUtils";
import { useEffect, useState } from "react";
import GooglePlay from '../assets/googleplay.png'
import AppStore from '../assets/appstore.png'
import { Box } from '@mui/material';

const DownloadApp: React.FC = () => {

    const [userInfo, setUserInfo] = useState<UserInfo>()

    const handleUserInfo = () => {
        try {
            const ui = getSavedUserInfo()
            setUserInfo(ui)
        } catch (error) {
            console.error('User info non presenti nel database - DESKTOP');
        }

    }

    useEffect(() => {
        handleUserInfo()
    }, [])

    return (<>
        <h2>Scarica l'app!</h2>
        <Grid container spacing={0}>

            <Grid size={3}>
                <img src={AppStore} alt="App Store" />
            </Grid>
            <Grid size={3}>
                <img src={GooglePlay} alt="App Store" style={{height: '45px'}}/>
            </Grid>
        </Grid>
    </>)
}
export default DownloadApp;