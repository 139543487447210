import { Box } from "@mui/material"
import { useState } from "react";

interface UserIconProps {
    firstName?: string
    lastName?: string
}

const getRandomLightColor = () => {
    const min = 100;
    const r = Math.floor(Math.random() * (256 - min) + min);
    const g = Math.floor(Math.random() * (256 - min) + min);
    const b = Math.floor(Math.random() * (256 - min) + min);
    let color = `rgb(${r}, ${g}, ${b})`;
    return color
};

const UserIcon: React.FC<UserIconProps> = ({ firstName, lastName }) => {

    const [coloIcon, setColoIcon] = useState<string>(getRandomLightColor())

    return (<Box sx={{
        height: 50,
        width: 50,
        borderRadius: 500,
        backgroundColor: coloIcon,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }}>
        <h2 style={{ margin: 0, fontSize: '1.2rem', color: 'white' }}>
            {firstName?.substring(0, 1).toUpperCase()}{lastName?.substring(0, 1).toUpperCase()}
        </h2>
    </Box>)
}

export default UserIcon