import { Typography } from "@mui/material"

interface AccountProps {

    piva?: string
    vat_address?: string
    email?: string
    email_verified: boolean
    preferred_username?: string
    given_name?: string
    family_name?: string
}

const Account = (props: AccountProps) => {

    const { piva, vat_address, email, email_verified, preferred_username, given_name, family_name } = props

    return (<>
        <Typography>email | nome | cognome | username | cambia password</Typography>
    </>)

}

export default Account