import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import QrCodeIcon from '@mui/icons-material/QrCode';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import NavBarMenu from './NavBarMenu';
import FooterMenu from './FooterMenu';
import Dashboard from '../pages/Dashboard';
import { Button } from '@mui/material';
import CustomDialog from './CustomDialog';
import QrCodeGenerator from './QrCodeGenerator';
import { getSavedUserInfo } from '../utils/localStorageUtils';
import ShareTab from './ShareTab';
import { userInfo } from 'os';
import { getShareMessage } from '../utils/dataUtils';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const DrawerContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  height: "100%", // Imposta l'altezza del drawer al 100% per occupare tutta l'altezza disponibile
  justifyContent: "space-between", // Spazio tra il contenuto e il footer
});

const ContentContainer = styled("div")({
  flexGrow: 1, // Questo permette di espandere il contenuto centrale, lasciando spazio tra NavBarMenu e FooterMenu
});


interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    variants: [
      {
        props: ({ open }) => open,
        style: {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': openedMixin(theme),
        },
      },
      {
        props: ({ open }) => !open,
        style: {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme),
        },
      },
    ],
  }),
);

interface MuiDrawerProps {
  [key: string]: (...args: any[]) => any;
}

export default function MiniDrawer(props: MuiDrawerProps) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openQrDialog, setOpenQrDialog] = React.useState(false);
  const [content, setContent] = React.useState<React.ReactNode>(<Dashboard />);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleOpenQrDialog = () => {
    console.log('apro la dialog');
    setOpenQrDialog(true)
  }

  const handleCloseQrDialog = () => {
    setOpenQrDialog(false)
  }

  const hadleSavedUserInfo = () => {
    try {
      return getSavedUserInfo()
    } catch (error) {
      console.error('User info non presenti nel database - DRAWER');
    }
  }

  const getUserName = () => {
    const userInfo = hadleSavedUserInfo()
    return userInfo?.preferred_username
  }

  const handleQrCodeData = () => {
    const userInfo = hadleSavedUserInfo()

    const host = process.env.REACT_APP_GUI_URL
    return `https://${host}/login/mobile/invite/${userInfo?.sub}`

  }

  const handleMessage = () => {
    const userInfo = hadleSavedUserInfo()

    if(!userInfo){
      return "";
    }

    return getShareMessage(userInfo.given_name, userInfo.family_name, handleQrCodeData())
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" open={open}>
        <Toolbar style={{ backgroundColor: "white", color: "black" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={[
              {
                marginRight: 5,
              },
              open && { display: 'none' },
            ]}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            DietMatch
          </Typography>
          <IconButton
            color="inherit"
            aria-label="qr code"
            onClick={handleOpenQrDialog}
            edge="end"
            sx={[
              {
                marginLeft: 5,
              }
            ]}
          >
            <QrCodeIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {/*SIDEBAR*/}
      <Drawer variant="permanent" open={open}>
        <DrawerContainer>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <NavBarMenu
            open={open}
            setContent={setContent}
          />

          <ContentContainer />

          {open ? <Divider /> : undefined}
          <FooterMenu
            open={open}
            setContent={setContent}
            onClick={props['logout']}
          />
        </DrawerContainer>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {content}
      </Box>
      <CustomDialog
        open={openQrDialog}
        onClose={handleCloseQrDialog}
        dialogTitle='Profile QRCode'
        dialogTopContent={<Typography variant='h5'>{getUserName()}</Typography>}
        actions={<ShareTab
          url={handleQrCodeData()}
          title={handleMessage()}
        />}
        dialogContent='
              Condividi il tuo profilo con i tuoi clienti per riuscire a gestirli.'
        children={<QrCodeGenerator
          data={handleQrCodeData()}
        />} />
    </Box>
  );
}
